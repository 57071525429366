import React, { useState, useEffect, lazy, Suspense, useContext, startTransition } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../../utils/contexts/userContext";

const AdminDetails = lazy(() => import("./AdminDetails"));
const EnlistRequest = lazy(() => import("./EnlistRequest"));
const VerifyUsers = lazy(() => import("./VerifyUsers"));
const AssignCourse = lazy(() => import("./AssignCourse"));
const ManageCategories = lazy(() => import("./ManageCategory/CategoryMain"));
const FestivalManagementSegment = lazy(() => import("./ManageFestivals/ManageFestiival"));
const CoursesMainPage = lazy(() => import("./ManageCourses/CoursesMain"));
const HelpDesk = lazy(() => import("./HelpDesk"));

const LoadingFallback = () => (
  <div className="text-black text-xl min-h-[27rem] flex justify-center items-center">Loading...</div>
);

const AdminDashboard = () => {
  const [select, setSelect] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { token, loading: userLoading } = useContext(UserContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    if (userLoading) return;

    const verifyAdminAccess = async () => {
      if (!token) {
        navigate("/signin");
        return;
      }

      try {
        const response = await axios.post("/utils/verify-admin");

        if (response.status === 200) {
          setIsAuthorized(true);
          startTransition(() => {
            setSelect("admin");
          });
        } else {
          setIsAuthorized(false);
          navigate("/unauthorized");
        }
      } catch (error) {
        console.error("Error verifying admin access:", error);
        setIsAuthorized(false);
        navigate("/unauthorized");
      } finally {
        setIsLoading(false);
      }
    };

    verifyAdminAccess();
  }, [token, userLoading, navigate]);

  const menuItems = [
    { key: "admin", label: "Admin Details" },
    { key: "enlistRequests", label: "Course Enlist Requests" },
    { key: "assign", label: "Assign Course" },
    { key: "course", label: "Manage Course" },
    { key: "users-pcs", label: "All PCS Global's Users" },
    { key: "categories", label: "Manage Categories" },
    { key: "festival", label: "Manage Festivals" },
    { key: "helpdesk", label: "Help Desk + Instructors" },
  ];

  const getButtonClass = (buttonName) => (
    `block cursor-pointer py-2 px-4 text-white rounded-md mb-3 ${select === buttonName ? "bg-blue-700" : "bg-blue-500 hover:bg-blue-600"}`
  );

  const renderComponent = () => {
    if (isLoading) {
      return <LoadingFallback />;
    }

    if (!isAuthorized) {
      return null;
    }

    return (
      <div className="flex flex-col md:flex-row justify-center items-start p-4">
        <div className="w-full md:w-1/4 mb-4 md:mb-0 p-3">
          <div className="bg-gray-100 rounded-lg shadow-md p-4 ">
            <div className="flex justify-between items-center mb-4">
              <h4 className="text-lg text-center font-semibold text-gray-800">Admin Panel</h4>
              <button
                className="md:hidden bg-blue-500 text-white px-2 py-1 rounded"
                onClick={() => setMenuOpen(prev => !prev)}
              >
                {menuOpen ? 'Close' : 'Menu'}
              </button>
            </div>
            <div
              className={`transition-all duration-300 ease-in-out overflow-hidden md:block text-start 
                ${menuOpen ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0 md:max-h-full md:opacity-100'}`}
            >
              {menuItems.map((item) => (
                <div
                  key={item.key}
                  className={getButtonClass(item.key)}
                  onClick={() => startTransition(() => {
                    setSelect(item.key);
                    setMenuOpen(false);
                  })}
                >
                  <h4 className="pl-3">{item.label}</h4>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-full lg:min-h-[40rem] flex justify-center items-center md:w-3/4 md:pl-4">
          {select && renderSelectedComponent(select)}
        </div>
      </div>
    );
  };

  const renderSelectedComponent = (select) => {
    switch (select) {
      case "admin":
        return <AdminDetails />;
      case "assign":
        return <Suspense fallback={<LoadingFallback />}><AssignCourse /></Suspense>;
      case "course":
        return <Suspense fallback={<LoadingFallback />}><CoursesMainPage /></Suspense>;
      case "enlistRequests":
        return <Suspense fallback={<LoadingFallback />}><EnlistRequest /></Suspense>;
      case "users-pcs":
        return <Suspense fallback={<LoadingFallback />}><VerifyUsers /></Suspense>;
      case "categories":
        return <Suspense fallback={<LoadingFallback />}><ManageCategories /></Suspense>;
      case "festival":
        return <Suspense fallback={<LoadingFallback />}><FestivalManagementSegment /></Suspense>;
      case "helpdesk":
        return <Suspense fallback={<LoadingFallback />}><HelpDesk /></Suspense>;
      default:
        return null;
    }
  };

  return renderComponent();
};

export default AdminDashboard;
