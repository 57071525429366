import React, { useState, useContext, useEffect } from 'react';
import VideoPlayerSkeleton from '../../../components/skeletons/VideoPlayerSkeleton';
import { useParams } from 'react-router-dom';
import formatViewCount from '../../../utils/functions/formatViews';
import axios from "axios";
import { UserContext } from '../../../utils/contexts/userContext';
import { lazy, Suspense } from 'react';

// Lazy load the ReviewDialog component
const ReviewDialog = lazy(() => import('../../../components/ReviewDialog'));
const LoginPopup = lazy(() => import('../../../components/LoginPopup'));

const VideoPlayerComponent = ({ videos, ytPlayListId }) => {
    const [currentVideo, setCurrentVideo] = useState(videos[0]);
    const [isRatingOpen, setIsRatingOpen] = useState(false);
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const { user } = useContext(UserContext);

    const embedUrl = `https://www.youtube.com/embed/${currentVideo.videoId}?controls=1&rel=0&modestbranding=1&showinfo=0`;

    const handleRateNowClick = () => {
        if (user) {
            setIsRatingOpen(true);
        } else {
            setShowLoginPopup(true);
        }
    };

    return (
        <div className="flex flex-col lg:flex-row items-start w-full max-w-7xl mx-auto bg-gray-800 rounded-3xl shadow-lg overflow-hidden">
            <div className="w-full lg:w-2/3 bg-black rounded-t-3xl lg:rounded-l-3xl lg:rounded-tr-none overflow-hidden">
                <div className="relative pt-[56.25%]">
                    <iframe
                        className="absolute top-0 left-0 w-full h-full"
                        src={embedUrl}
                        title={currentVideo.title}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
                <div className="p-4 bg-gray-700">
                    <h2 className="text-xl md:text-2xl font-bold mb-2 text-white">{currentVideo.title}</h2>
                    <div className="flex justify-between items-center">
                        <p className="text-sm text-gray-300">{formatViewCount(currentVideo.viewCount)} views</p>
                        <div className="hidden lg:block">
                            <span className="text-white mr-2">Liking the course?</span>
                            <button
                                onClick={handleRateNowClick}
                                className="text-blue-400 hover:text-blue-300 transition-colors duration-200"
                            >
                                Rate now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full lg:w-1/3 overflow-y-auto max-h-[calc(100vh-16rem)] lg:max-h-[calc(100vh-2rem)] bg-gray-700 rounded-b-3xl lg:rounded-r-3xl lg:rounded-bl-none">
                {videos.map((video) => (
                    <div
                        key={video._id}
                        className={`flex items-center p-3 hover:bg-gray-600 cursor-pointer transition-colors duration-200 ${video._id === currentVideo._id ? 'bg-blue-500' : ''}`}
                        onClick={() => setCurrentVideo(video)}
                    >
                        <div className="flex-shrink-0 w-24 h-16 mr-3">
                            <img
                                src={video.thumbnail}
                                alt={video.title}
                                className="w-full h-full object-cover rounded-lg"
                            />
                        </div>
                        <div className="flex-grow">
                            <h3 className="text-sm font-semibold line-clamp-2 text-white">{video.title}</h3>
                            <span className="text-xs text-gray-300">
                                {formatViewCount(video.viewCount)} views
                            </span>
                        </div>
                    </div>
                ))}
            </div>
            <div className="lg:hidden w-full p-4 bg-gray-700">
                <div className="flex justify-center items-center">
                    <span className="text-white mr-2">Liking the course?</span>
                    <button
                        onClick={handleRateNowClick}
                        className="text-blue-400 hover:text-blue-300 transition-colors duration-200"
                    >
                        Rate now
                    </button>
                </div>
            </div>
            <Suspense fallback={<div>Loading...</div>}>
                <ReviewDialog
                    isOpen={isRatingOpen}
                    onClose={() => setIsRatingOpen(false)}
                    playlistId={ytPlayListId}
                    userEmail={user?.email}
                />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
                {showLoginPopup && <LoginPopup
                    onClose={() => setShowLoginPopup(false)}
                    title="Login Required"
                    message="You need to login first to rate the video."
                />}
            </Suspense>
        </div>
    );
};

const VideoPlayer = () => {
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { ytPlayListId } = useParams();

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                if (!ytPlayListId) {
                    throw new Error("ytPlayListId is required");
                }
                const response = await axios.get(`/course/getVideos?ytPlayListId=${ytPlayListId}`);
                setVideos(response.data);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };
        fetchVideos();
    }, [ytPlayListId]);

    return (
        <div className="p-4 md:p-8 bg-gray-900 min-h-screen">
            {loading ? (
                <VideoPlayerSkeleton />
            ) : error ? (
                <p className="text-center text-xl font-semibold text-red-500">{error}</p>
            ) : videos.length > 0 ? (
                <VideoPlayerComponent
                    ytPlayListId={ytPlayListId}
                    videos={videos} />
            ) : (
                <p className="text-center text-xl font-semibold text-white">No videos found.</p>
            )}
        </div>
    );
}

export default VideoPlayer;
