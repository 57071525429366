import React from 'react';
import { FileText, Upload, CheckCircle, GraduationCap } from 'lucide-react';

const ProcessStep = ({ icon: Icon, title, description }) => (
    <div className="relative flex flex-col items-center max-w-sm p-3 bg-white rounded-lg shadow-md z-10 min-h-48">
        <div className="mb-3 relative">
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                <Icon className="w-6 h-6 text-blue-500" />
            </div>
            <div className="w-12 h-12 rounded-full bg-blue-100"></div>
        </div>
        <h3 className="text-base font-semibold text-gray-700 mb-1">{title}</h3>
        <p className="text-sm text-gray-600 text-center">{description}</p>
    </div>
);

const CourseEnlistmentProcessLg = () => {

    return (

        <div className="bg-white p-8 rounded-lg shadow-md min-h-[22rem]">
            <h2 className="text-2xl font-bold text-gray-800 mb-8 text-center">Course Enlistment Process</h2>
            <div className="flex justify-between items-center relative h-full gap-x-8">
                <ProcessStep
                    icon={FileText}
                    title="Enter Course Details"
                    description="Fill out the form with your course title, description, and other relevant details."
                />
                <ProcessStep
                    icon={Upload}
                    title="Course Submitted for Verification"
                    description="We verify that the playlist enlist request is made by the actual owner of the playlist."
                />
                <ProcessStep
                    icon={CheckCircle}
                    title="Approval or Rejection"
                    description="Our team carefully reviews your course. You'll be notified of the decision."
                />
                <ProcessStep
                    icon={GraduationCap}
                    title="Addition to Courses Section"
                    description="If approved, your course is added to the main courses section for all users to access."
                />
                <div className="absolute top-1/2 left-0 right-0 h-0.5 bg-gray-300 z-0"></div>
            </div>
           
        </div>
    );

    }
    export default CourseEnlistmentProcessLg;
