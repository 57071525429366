import React from "react";

const CourseCardSkeleton = ({key}) => (
    <div className="lg:h-[16.4rem] h-[270px] min-w-[18rem] bg-gray-200 rounded-lg animate-pulse lg:w-[18rem]" key={key}>
        <div className="h-3/5 bg-gray-300 rounded-t-lg"></div>
        <div className="p-4 space-y-3">
            <div className="h-4 bg-gray-300 rounded w-3/4"></div>
            <div className="h-4 bg-gray-300 rounded w-1/2"></div>
            <div className="h-4 bg-gray-300 rounded w-1/4"></div>
        </div>
    </div>
);

export default CourseCardSkeleton;