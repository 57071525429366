// src/components/LoginPopup.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { XCircle } from 'lucide-react';

const LoginPopup = ({ onClose, title, message }) => {
    const navigate = useNavigate();

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
            <div className="bg-white rounded-lg shadow-2xl max-w-md w-full overflow-hidden transform transition-all">
                <div className="relative bg-gradient-to-r from-blue-500 to-blue-600 px-6 py-4">
                    <h2 className="text-2xl font-bold text-white">{title}</h2>
                    <button
                        onClick={onClose}
                        className="absolute top-4 right-4 text-white hover:text-gray-200 transition-colors"
                        aria-label="Close"
                    >
                        <XCircle size={24} />
                    </button>
                </div>
                <div className="px-6 py-4">
                    <p className="text-gray-600 mb-6">{message}</p>
                    <div className="flex flex-col sm:flex-row justify-end gap-3">
                        <button
                            onClick={onClose}
                            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-75"
                        >
                            Close
                        </button>
                        <button
                            onClick={() => navigate('/signin')}
                            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
                        >
                            Go to Sign In
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPopup;
