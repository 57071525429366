import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { UserPlus, DollarSign, Clock, Lightbulb } from 'lucide-react';

const BenefitCard = ({ icon, title, description }) => (
    <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center text-center">
        <div className="text-blue-500 mb-4">
            {icon}
        </div>
        <h3 className="text-xl font-semibold mb-2 text-gray-800">{title}</h3>
        <p className="text-gray-600">{description}</p>
    </div>
);

const Instructor = () => {
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [domain, setDomain] = useState("");
    const [expanded, setExpanded] = useState(false);
    const divRef = useRef(null);
    const domains = [
        { value: 'EILP MERN STACK DEVELOPER', label: 'MERN Stack Developer' },
        { value: 'EILP JAVA FULL STACK DEVELOPER', label: 'Java Full Stack Developer' },
        { value: 'EILP DATA ANALYST', label: 'Data Analyst' },
        { value: 'EILP SALESFORCE', label: 'Salesforce' },
        { value: 'EILP PYTHON DEVELOPER', label: 'Python Developer' }
    ];

    const handleDivClick = () => {
        setExpanded(!expanded);
    };

    const handleCloseClick = (e) => {
        e.stopPropagation();
        setExpanded(false);
    };

    const handleClickOutside = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {
            setExpanded(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleDomainChange = (e) => {
        console.log("Selected domain:", e.target.value); // Debugging
        setDomain(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post('/api/becomeAnInstructor', { email, phone, domain });
            if (res && res.data.success) {
                toast.success(res.data.message);
                setExpanded(false);
            } else if (!res.data.success) {
                toast.error(res.data.message);
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <div className="py-10 px-4 sm:px-6 lg:px-8 bg-white">
            <div className="max-w-7xl mx-auto">
                <img src={require('../../images/samarthnaik.jpg')} className="rounded-lg w-full mb-12" alt="Samarth Naik" />

                <h2 className="text-3xl font-bold text-center mb-8 text-gray-800">Why Become an Instructor?</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
                    <BenefitCard
                        icon={<UserPlus size={48} />}
                        title="Impact Lives"
                        description="Share your knowledge and skills to help others grow in their careers."
                    />
                    <BenefitCard
                        icon={<DollarSign size={48} />}
                        title="Earn More"
                        description="Create an additional income stream by teaching what you love."
                    />
                    <BenefitCard
                        icon={<Clock size={48} />}
                        title="Flexible Schedule"
                        description="Teach on your own time and from anywhere in the world."
                    />
                    <BenefitCard
                        icon={<Lightbulb size={48} />}
                        title="Grow Professionally"
                        description="Enhance your own skills and stay updated with industry trends."
                    />
                </div>

                <div className="flex justify-center items-center h-[25rem]">
                    <div
                        ref={divRef}
                        onClick={handleDivClick}
                        className={`
        flex flex-col justify-center items-center
        rounded-xl bg-sky-950 cursor-pointer
        transition-all duration-500 ease-in-out 
        ${expanded ? 'w-96 h-auto p-6' : 'w-64 h-16'}
        relative
    `}
                    >
                        <span className={`
        text-slate-200 font-extrabold text-xl
        transition-all duration-500 ease-in-out absolute 
        ${expanded ? 'opacity-0 scale-0' : 'opacity-100 scale-100'}
        z-20
    `}>
                            Get Started
                        </span>
                        <div className={`
        w-full transition-all duration-500 ease-in-out
        ${expanded ? 'opacity-100 scale-100' : 'opacity-0 scale-0'}
        absolute -top-[10rem] left-0 p-6 
        bg-gray-100 rounded-xl shadow-lg
        z-10
    `}>
                            <div className="flex justify-end w-full mb-4" onClick={handleCloseClick}>
                                <div className="bg-sky-950 rounded-full border-2 p-1 border-white cursor-pointer hover:bg-sky-900 transition-colors duration-300">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        fill="none" viewBox="0 0 24 24"
                                        strokeWidth="3" stroke="white" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            </div>

                            <h1 className="text-xl font-bold mb-4 text-black">Become an Instructor</h1>
                            <div className="pt-8" onClick={(e) => e.stopPropagation()}> {/* Stop propagation here */}
                                <form onSubmit={handleSubmit} className="space-y-4">
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        className="w-full p-2 rounded-md border border-gray-300 text-black bg-white"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                    <input
                                        type="tel"
                                        placeholder="Phone No"
                                        className="w-full p-2 rounded-md border border-gray-300 text-black bg-white"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        required
                                    />
                                    <select
                                        value={domain}
                                        onChange={handleDomainChange}
                                        className="w-full p-2 rounded-md border border-gray-300 text-black bg-white"
                                    >
                                        <option value="" disabled>Select Domain</option>
                                        {domains.map((option, index) => (
                                            <option key={index} value={option.value}>{option.label}</option>
                                        ))}
                                    </select>
                                    <button type="submit" className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300">
                                        Contact
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Instructor;
