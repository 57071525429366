import { toast } from "react-hot-toast";
import axios from "axios";
import isValidEmail from "../functions/isValidEmail";
import isValidPhoneNumber from "../functions/isValidPhoneNumber";
import { validatePasswordStrength } from "../../utils/functions/passWordValidation"
const EMPLOYEE_ID_REGEX = /^\d{1,4}$/;

const useRegistration = () => {

    const registerHandler = async (userType, formData) => {
        const commonValidations = [
            () => {
                if (!formData.password || !formData.confirmpassword) {
                    return "Both password fields must be filled";
                }
                return formData.password === formData.confirmpassword || "Password does not match with confirm password";
            },
            () => validatePasswordStrength(formData.password),
            () => isValidEmail(formData.email) || "Please Enter a Valid Email",
        ];

        const validations = {
            User: [
                () => formData.name !== '' || "Name is Required",
                () => !formData.email.endsWith('@pcsgpl.com') || "Please register as a PCS Employee if you have an official email id",
                ...commonValidations
            ],
            Administrator: [
                () => formData.firstname !== '' || "First Name is required",
                () => formData.lastname !== '' || "Last Name is required",
                ...commonValidations,
                () => formData.email.endsWith('@pcsgpl.com') || "Not a valid official email id",
                () => isValidPhoneNumber(formData.phoneno) || "Enter A valid Phone Number",
                () => EMPLOYEE_ID_REGEX.test(formData.employee) || "Employee ID must be a 4-digit number"
            ]
        };

        for (const validate of validations[userType]) {
            const result = validate();
            if (result !== true) {
                toast.error(result);
                return false;
            }
        }

        try {
            const endpoint = userType === 'User' ? "/auth/register" : "/auth/register-pcs";
            const payload = userType === 'User'
                ? {
                    name: formData.name,
                    email: formData.email,
                    password: formData.password,
                }
                : {
                    firstName: formData.firstname,
                    lastName: formData.lastname,
                    email: formData.email,
                    password: formData.password,
                    employeeId: formData.employee,
                    phoneNo: formData.phoneno
                };

            const res = await axios.post(endpoint, payload);

            if (res.data?.success) {
                if (userType === 'User') {
                    toast.success("Registration Successful. Please verify your email to login.");
                }
                if (userType === 'Administrator') {
                    toast.success("Registration Successful. Please wait for admin approval.");
                }
                return true;
            } 
        } catch (error) {
            toast.error(error.response?.data?.message || "Registration failed. Please try again.");
            console.error("Registration error:", error);
            return false;
        }
    };

    return { registerHandler };
};

export default useRegistration;
