import React from "react";
// import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Navbar from "../components/navbar/Navbar";
import TopBanner from "../components/TopBanner";
import { CategoryProvider } from "../utils/contexts/categoryContext";

const Layout = ({ children, searchTerm, setSearchTerm,   }) => {
  return (
    <>
      <header
        className="mx-auto ">
        <TopBanner />
        <CategoryProvider>
          <Navbar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </CategoryProvider>

      </header>
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
