import React from 'react';

const RegistrationSuccessPage = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-[30rem] bg-gray-100">
            <div className="text-center p-8 bg-white rounded-lg shadow-lg max-w-md w-full">
                <h1 className="text-4xl font-bold text-blue-500 mb-6">
                    Successfully Registered!
                </h1>
                <p className="text-xl text-gray-700 mb-8">
                    Please verify your email to login.
                </p>
                <div className="bg-blue-500 text-white py-3 px-6 rounded-full inline-block">
                    Check Your Email
                </div>
                <p className="mt-6 text-sm text-gray-500">
                    Didn't receive an email? Check your spam folder or contact support.
                </p>
            </div>
        </div>
    );
};

export default RegistrationSuccessPage;