import React from 'react'

const VideoPlayerSkeleton = () => (
    <div className="flex flex-col lg:flex-row items-start w-full max-w-7xl mx-auto bg-gray-800 rounded-3xl shadow-lg overflow-hidden animate-pulse">
        <div className="w-full lg:w-2/3 bg-gray-700 rounded-t-3xl lg:rounded-l-3xl lg:rounded-tr-none overflow-hidden">
            <div className="relative pt-[56.25%] bg-gray-600"></div>
            <div className="p-4">
                <div className="h-6 bg-gray-600 rounded w-3/4 mb-2"></div>
                <div className="h-4 bg-gray-600 rounded w-1/4"></div>
            </div>
        </div>
        <div className="w-full lg:w-1/3 bg-gray-700 rounded-b-3xl lg:rounded-r-3xl lg:rounded-bl-none">
            {[...Array(5)].map((_, index) => (
                <div key={index} className="flex items-center p-3">
                    <div className="flex-shrink-0 w-24 h-16 mr-3 bg-gray-600 rounded-lg"></div>
                    <div className="flex-grow">
                        <div className="h-4 bg-gray-600 rounded w-3/4 mb-2"></div>
                        <div className="h-3 bg-gray-600 rounded w-1/4"></div>
                    </div>
                </div>
            ))}
        </div>
    </div>
);

export default VideoPlayerSkeleton;