import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from "react-hot-toast";
import { validatePasswordStrength } from "../../../utils/functions/passWordValidation";
import LoadAndErrorButton from '../../../components/buttons/LoadAndErrorButton';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [buttonState, setButtonState] = useState('neutral');
  const [countdown, setCountdown] = useState(null);
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      setError('Invalid reset link. Please request a new password reset.');
    }
  }, [token]);

  useEffect(() => {
    let timer;
    if (countdown !== null && countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else if (countdown === 0) {
      navigate('/signin');
    }
    return () => clearTimeout(timer);
  }, [countdown, navigate]);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError('');
    setButtonState('loading');

    const passwordValidation = validatePasswordStrength(newPassword);
    if (passwordValidation !== true) {
      setError(passwordValidation);
      setButtonState('error');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      setButtonState('error');
      return;
    }

    try {
      const response = await axios.post('/auth/reset-password', { token, newPassword });
      if (response.data.success) {
        setButtonState('success');
        toast.success('Password reset successfully');
        setCountdown(3);
      } else {
        setError(response.data.message);
        setButtonState('error');
      }
    } catch (error) {
      setError(error.response?.data?.message || 'An error occurred. Please try again.');
      setButtonState('error');
    }
  };

  return (
    <div className="min-h-[27rem] bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-lg shadow-md">
        <div>
          <h2 className="text-center text-3xl font-extrabold text-gray-900">Reset Your Password</h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleResetPassword}>
          <div className="space-y-4">
            <div>
              <label htmlFor="newPassword" className="sr-only">New Password</label>
              <input
                id="newPassword"
                name="newPassword"
                type="text" // Always show password as text
                required
                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 bg-gray-100 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="confirmPassword" className="sr-only">Confirm New Password</label>
              <input
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                required
                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 bg-gray-100 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Confirm New Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>

          {error && <p className="text-red-500 text-sm text-center mt-2">{error}</p>}
          {countdown !== null && (
            <p className="text-green-500 text-sm text-center mt-2">
              Redirecting to login page in {countdown} second{countdown !== 1 ? 's' : ''}...
            </p>
          )}

          <div>
            <LoadAndErrorButton
              text="Reset Password"
              onClick={handleResetPassword}
              isEnabled={newPassword && confirmPassword && buttonState === 'neutral'}
              variant={buttonState}
              className="w-full"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
