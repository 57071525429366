import React, { useContext } from "react";
import { UserContext } from "../../utils/contexts/userContext";
import { User, Mail, Phone, CreditCard, UserCircle } from "lucide-react";

const UserDetails = ({ user }) => {
  if (!user) return null;

  // Split user.name into first and last name if user.firstName and user.lastName are not available
  const getFirstName = () => user.firstName || (user.name && user.name.split(" ")[0]) || "N/A";
  const getLastName = () => user.lastName || (user.name && user.name.split(" ").slice(1).join(" ")) || "N/A";

  const DetailRow = ({ icon, label, value }) => (
    <div className="flex items-center mb-4 text-gray-700">
      {React.cloneElement(icon, { className: "flex-shrink-0" })}
      <span className="font-semibold mr-2 ml-3 whitespace-nowrap">{label}:</span>
      <span className="truncate">{value || "N/A"}</span>
    </div>
  );

  return (
    <div className="bg-white p-6 sm:p-8 rounded-xl shadow-lg w-full max-w-4xl mx-auto">
      <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-gray-800 border-b pb-2">
        {user.userType === "employee" ? "Employee Details" : "User Details"}
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <DetailRow icon={<User size={20} />} label="First Name" value={getFirstName()} />
        <DetailRow icon={<User size={20} />} label="Last Name" value={getLastName()} />
        <DetailRow icon={<Mail size={20} />} label="Email" value={user.email} />
        <DetailRow icon={<Phone size={20} />} label="Phone" value={user.phoneNo} />
        {user.userType === "employee" && (
          <>
            <DetailRow icon={<CreditCard size={20} />} label="Employee ID" value={user.employeeId} />
            <DetailRow icon={<UserCircle size={20} />} label="User Type" value={user.userType} />
          </>
        )}
      </div>
    </div>
  );
};

const UserDashboard = () => {
  const { user, loading } = useContext(UserContext);

  if (loading) {
    return <div className="min-h-[27rem] bg-gray-100 flex flex-col items-center justify-center p-4">Loading...</div>;
  }

  if (!user) {
    return <div className="min-h-[27rem] bg-gray-100 flex flex-col items-center justify-center p-4">No user data available.</div>;
  }

  const getFirstName = (user) => user.firstName || (user.name && user.name.split(" ")[0]) || "User";

  return (
    <div className="min-h-[27rem] bg-gray-100 flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-6xl">
        <div className="text-center mb-8">
          <p className="text-gray-600 mt-2">Welcome back, {getFirstName(user)}!</p>
        </div>
        <UserDetails user={user} />
      </div>
    </div>
  );
};

export default UserDashboard;
