import React from 'react';
import CourseCardSkeleton from './CourseCardSkeleton';

const CoursesSkeletonton = () => (
    <>
        {[1, 2].map((category) => (
            <div key={category} className="w-full flex flex-col justify-center pt-1 mb-8 overflow-hidden">
                <div className="w-48 h-8 bg-gray-200 rounded mb-4 animate-pulse"></div>
                <div className="flex flex-nowrap space-x-4 md:space-x-6 lg:space-x-10 overflow-x-hidden pb-4">
                    {[1, 2, 3, 4].map((card) => (
                        <div key={card} className={`
                            flex-shrink-0 
                            w-[86vw] sm:w-full sm:max-w-[280px]
                            ${card === 1 ? 'block' : 'hidden sm:block'}
                            ${card > 2 ? 'hidden md:block' : ''}
                            ${card > 3 ? 'hidden lg:block' : ''}
                        `}>
                            <CourseCardSkeleton />
                        </div>
                    ))}
                </div>
            </div>
        ))}
    </>
);

export default CoursesSkeletonton;