import React from 'react';

const CourseFormSkeleton = () => {
    return (
        <div className="bg-gray-100 p-8 animate-pulse">
            <div className="text-2xl font-bold mb-6 bg-gray-300 h-8 w-3/4 rounded"></div>

            <div className="flex flex-col md:flex-row gap-8">
                {/* Thumbnail upload skeleton */}
                <div className="w-full md:w-1/2">
                    <div className="text-lg font-semibold mb-2 bg-gray-300 h-6 w-1/2 rounded"></div>
                    <div className="border-2 border-dashed border-gray-300 rounded-lg h-64 flex items-center justify-center">
                        <div className="w-12 h-12 bg-gray-300 rounded-full"></div>
                    </div>
                </div>

                {/* Form fields skeleton */}
                <div className="w-full md:w-1/2 space-y-4">
                    {[...Array(5)].map((_, index) => (
                        <div key={index}>
                            <div className="bg-gray-300 h-5 w-1/3 rounded mb-2"></div>
                            <div className="bg-gray-200 h-10 w-full rounded"></div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Next button skeleton */}
            <div className="mt-8 flex justify-center items-center">
                <div className="bg-blue-300 h-12 w-[30%] rounded"></div>
            </div>
        </div>
    );
};

export default CourseFormSkeleton;