// passwordValidation.js

const PASSWORD_REGEX = {
    minLength: 8,
    hasLowerCase: /[a-z]/,
    hasUpperCase: /[A-Z]/,
    hasDigit: /\d/,
    hasSpecialChar: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/
};

export const validatePasswordStrength = (password) => {
    if (password.length < PASSWORD_REGEX.minLength) {
        return `Password must be at least ${PASSWORD_REGEX.minLength} characters long`;
    }

    const checks = [
        { condition: PASSWORD_REGEX.hasLowerCase, message: "Password must contain at least one lowercase letter" },
        { condition: PASSWORD_REGEX.hasUpperCase, message: "Password must contain at least one uppercase letter" },
        { condition: PASSWORD_REGEX.hasDigit, message: "Password must contain at least one numeric digit" },
        { condition: PASSWORD_REGEX.hasSpecialChar, message: "Password must contain at least one special character" }
    ];

    for (const check of checks) {
        if (!check.condition.test(password)) {
            return check.message;
        }
    }

    return true;
};