import React, { Suspense, useState, useEffect, useContext } from "react";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'primeicons/primeicons.css';
import CourseCard from "../../../components/CourseCard";
import CourseCardSkeleton from '../../../components/skeletons/CourseCardSkeleton';
import { UserContext } from "../../../utils/contexts/userContext";
import { useNavigate } from 'react-router-dom';
import MinimalistTabs from "../../../components/MinimalistTabs";
import { motion } from "framer-motion";

const Dashboard = ({ searchTerm }) => {
  const [coursesByCategory, setCoursesByCategory] = useState({});
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("All Courses");
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const tabs = user?.userType === "employee" ? ["All Courses", "Employee Only Courses", "Special Access Courses"] : ["All Courses"];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let endpoint = "/course/getALlBuyerCourseCards";
        if (user?.userType === "employee" && activeTab === "Employee Only Courses") {
          endpoint = "/course/getALlEmployeeCourseCards";
        }
        if (user?.userType === "employee" && activeTab === "Special Access Courses") {
          endpoint = `/course/get-special-access-course?email=${user.email}&employeeId=${user.employeeId}`;
        }
        if (searchTerm) {
          endpoint += `?search=${searchTerm}`;
        }

        const res = await axios.get(endpoint);
        const courses = res.data.dashboards;

        const groupedCourses = courses.reduce((acc, course) => {
          if (!acc[course.category]) {
            acc[course.category] = [];
          }
          acc[course.category].push(course);
          return acc;
        }, {});

        setCoursesByCategory(groupedCourses);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [searchTerm, activeTab, user]);

  const CarouselArrow = ({ direction, onClick, slideCount, currentSlide }) => {
    const isNext = direction === 'next';
    const isPrev = direction === 'prev';

    const isHidden = slideCount < 4 || (isPrev && currentSlide === 0);

    if (isHidden) return null;

    return (
      <button
        onClick={onClick}
        className={`absolute ${isNext ? 'right-0' : 'left-0'} top-1/2 -translate-y-1/2 z-10 bg-white border rounded-full p-2`}
      >
        <i className={`pi ${isNext ? 'pi-chevron-right text-black' : 'pi-chevron-left text-black'}`} />
      </button>
    );
  };

  const SliderSettings = (coursesCount) => ({
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: Math.min(3.6, coursesCount),
    slidesToScroll: 1,
    nextArrow: <CarouselArrow direction="next" />,
    prevArrow: <CarouselArrow direction="prev" />,
    rows: 1,
    slidesPerRow: 1,
    vertical: false,
    verticalSwiping: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(2, coursesCount),
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          arrows: false,
        }
      }
    ]
  });

  const SkeletonLoader = () => (
    <>
      {[1, 2].map((category) => (
        <motion.div
          key={category}
          className="w-full flex flex-col justify-center pt-1 mb-8 overflow-hidden"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="w-48 h-8 bg-gray-200 rounded mb-4 animate-pulse"></div>
          <div className="flex w-svw space-x-10 overflow-hidden">
            {[1, 2, 3, 4].map((card) => (
              <CourseCardSkeleton key={card} />
            ))}
          </div>
        </motion.div>
      ))}
    </>
  );

  const EmptyState = () => (
    <motion.div
      className="flex flex-col items-center justify-center h-64 w-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <i className="pi pi-info-circle text-4xl text-gray-400 mb-4"></i>
      <p className="text-xl text-gray-600">No employee-only courses uploaded yet.</p>
    </motion.div>
  );

  const SpecialAccessEmptyState = () => (
    <motion.div
      className="flex flex-col items-center justify-center h-64 w-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <i className="pi pi-info-circle text-4xl text-gray-400 mb-4"></i>
      <p className="text-xl text-gray-600 mb-4">No courses were assigned to you. Contact admin for special access courses.</p>
      <button
        className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors duration-300"
        onClick={() => navigate('/help')}
      >
        Contact Admin
      </button>
    </motion.div>
  );

  return (
    <div className="flex flex-col items-center mb-4 h-auto min-h-screen w-full justify-start bg-[#F5F5F5] px-5">
      {user?.userType === "employee" && (
        <MinimalistTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      )}
      {loading ? (
        <SkeletonLoader />
      ) : activeTab === "Special Access Courses" && Object.keys(coursesByCategory).length === 0 ? (
        <SpecialAccessEmptyState />
      ) : activeTab === "Employee Only Courses" && Object.keys(coursesByCategory).length === 0 ? (
        <EmptyState />
      ) : (
        Object.entries(coursesByCategory).map(([category, courses]) => (
          <motion.div
            key={category}
            className="w-full flex flex-col justify-center pt-1"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-2xl w-full pl-10 text-start text-black font-extrabold leading-tight">{category}</h2>
            <div className={`lg:slider-container ${courses.length < 4 ? 'lg:few-cards' : ''} ${courses.length === 2 ? 'two-cards' : ''} ${courses.length === 3 ? 'three-cards' : ''}`}>
              <Slider {...SliderSettings(courses.length)}>
                {courses.map((item) => (
                  <Suspense key={item._id} fallback={<div className="h-[300px] bg-gray-200 rounded-lg animate-pulse"></div>}>
                    <CourseCard item={item} />
                  </Suspense>
                ))}
              </Slider>
            </div>
          </motion.div>
        ))
      )}
    </div>
  );
};

export default Dashboard;
