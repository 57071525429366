import React, { useState, Suspense, useContext } from 'react';
import CourseEnlistmentProcessSm from './EnlistMentProcess/CourseEnlistmentProcessSm';
import CourseEnlistmentProcessLg from './EnlistMentProcess/CourseEnlistmentProcessLg';
import CourseFormSkeleton from '../../../components/skeletons/CourseFormSkeleoton';
import { UserContext } from '../../../utils/contexts/userContext';
import LoginPopup from '../../../components/LoginPopup';

const CourseForm = React.lazy(() => import('./CourseForm/CourseForm'));



const CourseEnlistment = () => {
    const [showForm, setShowForm] = useState(false);
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const { user } = useContext(UserContext);

    const handleProceedClick = () => {
        if (user) {
            setShowForm(true);
        } else {
            setShowLoginPopup(true);
        }
    };

    return (
        <div className='my-5 lg:mx-5'>
            {!showForm ? (
                <>
                    <div className="hidden lg:block">
                        <CourseEnlistmentProcessLg />
                    </div>
                    <div className="block lg:hidden">
                        <CourseEnlistmentProcessSm />
                    </div>

                    <button
                        onClick={handleProceedClick}
                        className="bg-blue-500 text-white py-3 px-8 rounded-md shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 text-lg font-bold mt-8"
                    >
                        Proceed to Form
                    </button>
                </>
            ) : (
                <Suspense fallback={<CourseFormSkeleton />}>
                    <CourseForm 
                        user={user}
                    />
                </Suspense>
            )}

            {showLoginPopup && <LoginPopup
                onClose={() => setShowLoginPopup(false)}
                title="Login Required"
                message="You need to login first to fill the form and start your course enlistment process."
            />}
        </div>
    );
};

export default CourseEnlistment;