import React, { useState,useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/shared/home/HomeMain';
import Layout from './pages/Layout';
import axios from 'axios';
import Dashboard from './pages/user/AllCourses/AllCourses';
import AdminDashboard from './pages/admin/admindashboard/AdminDashboard';
import PageNotFound from './pages/shared/PageNotFound';
import { Toaster } from 'react-hot-toast';
import Help from './pages/shared/help/Help';
import ResetPassword from './pages/shared/auth/ResetPassword';
import UserDashboard from './pages/user/UserDashboard';
import SignUp from './pages/shared/auth/signup/SiignUp';
import SignIn from './pages/shared/auth/signin/SignIn';
import EnlistMain from './pages/user/enlist-course/EnlistMain';
import Instructor from './pages/shared/Instructor';
import Construct from './pages/shared/Construct';
import Unauthorized from './pages/shared/Unauthorized';
import CourseDescription from './pages/user/AllCourses/CourseDescription';
import { CategoryProvider } from './utils/contexts/categoryContext';
import { UserProvider } from './utils/contexts/userContext';
import VideoPlayer from './pages/user/AllCourses/VideoPlayer';
import Category from './pages/shared/categories/Category';
import SubCategory from './pages/shared/categories/SubCategoy';
import SubSubCategory from './pages/shared/categories/SubSubCategory';
import RegistrationSuccessPage from './pages/shared/auth/signup/RegistrationSuccesPage';
import EmailVerification from './pages/shared/auth/signup/EmailVerification';
const token = localStorage.getItem('token');
// axios.defaults.baseURL = 'http://localhost:8000';
axios.defaults.baseURL = "https://pcs-global360-server.vercel.app/";
if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
const App = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (token) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        } else {
            delete axios.defaults.headers.common["Authorization"];
        }
    }, [token]);


    return (
        <div className="App">
            <BrowserRouter>
                <Toaster />
                <UserProvider>
                    <CategoryProvider>
                        <Layout
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                        >
                            <Routes>
                                <Route path="/construct" element={<Construct />} />


                                <Route path="/dashboard/:descriptionId">
                                    <Route index element={<CourseDescription />} />
                                    <Route path=":ytPlayListId" element={<VideoPlayer />} />
                                </Route>

                                <Route path="/category/:categoryName">
                                    <Route index element={<Category />} />
                                    <Route path=":descriptionId">
                                        <Route index element={<CourseDescription />} />
                                        <Route path=":ytPlayListId" element={<VideoPlayer />} />
                                    </Route>
                                </Route>

                                <Route path="/subcategory/:subcategoryName">
                                    <Route index element={<SubCategory />} />
                                    <Route path=":descriptionId">
                                        <Route index element={<CourseDescription />} />
                                        <Route path=":ytPlayListId" element={<VideoPlayer />} />
                                    </Route>
                                </Route>

                                <Route path="/subsubcategory/:subsubcategoryName">
                                    <Route index element={<SubSubCategory />} />
                                    <Route path=":descriptionId">
                                        <Route index element={<CourseDescription />} />
                                        <Route path=":ytPlayListId" element={<VideoPlayer />} />
                                    </Route>
                                </Route>



                                <Route path="/" element={<Home />} />
                                <Route
                                    path="/dashboard"
                                    element={<Dashboard searchTerm={searchTerm} setSearchTerm={setSearchTerm} />}
                                />
                                
                                <Route path="/enlist-course" element={<EnlistMain />} />
                                <Route path="/admin" element={<AdminDashboard />} />

                                <Route path="/user" element={<UserDashboard />} />
                                <Route path="/unauthorized" element={<Unauthorized />} />
                                <Route path="/teach" element={<Instructor />} />

                                <Route path="/signup" element={<SignUp />} />
                                <Route path="/signin" element={<SignIn />} />
                                <Route path="/RegistraionSucccess" element={<RegistrationSuccessPage />} />
                                <Route path="/verify-email/:token" element={<EmailVerification />} />
                                <Route path="/reset-password/:token" element={<ResetPassword />} />
                                <Route path="/help" element={<Help />} />
                                <Route path="*" element={<PageNotFound />} />
                            </Routes>
                        </Layout>
                    </CategoryProvider>
                </UserProvider>
            </BrowserRouter>
        </div >
    );
};

export default App;
