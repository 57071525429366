import React, { useState } from 'react';
import axios from 'axios';
import LoadAndErrorButton from '../../../components/buttons/LoadAndErrorButton';
import isValidEmail from '../../../utils/functions/isValidEmail';

const ForgotPasswordPopup = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleResetPassword = async () => {
    setError('');
    setSuccessMessage('');

    if (!isValidEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    setIsLoading(true);

    try {
      const res = await axios.post('/auth/request-password-reset', { email });
      if (res.data.success) {
        setSuccessMessage(res.data.message);
      } else {
        setError(res.data.message);
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded-lg shadow-lg w-96">
        <h2 className="text-2xl font-bold mb-6 text-blue-600">Forgot Password</h2>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="p-3 border border-gray-300 text-black rounded-lg w-full mb-6 bg-gray-100"
        />
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}
        <LoadAndErrorButton
          text="Send Reset Link"
          onClick={handleResetPassword}
          isEnabled={!isLoading && email.trim() !== ''}
          variant={isLoading ? "loading" : "neutral"}
          className="w-full mb-4"
        />
        <button
          onClick={onClose}
          className="mt-4 text-gray-500 hover:text-gray-700 w-full text-center"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ForgotPasswordPopup;