const MinimalistTabs = ({ tabs, activeTab, setActiveTab }) => {
    return (
        <div className="flex justify-center mb-4 border-b border-gray-300">
            {tabs.map((tab) => (
                <button
                    key={tab}
                    className={`px-4 py-2 font-medium text-gray-600 relative ${activeTab === tab ? 'text-blue-600' : ''}`}
                    onClick={() => setActiveTab(tab)}
                >
                    {tab}
                    {activeTab === tab && (
                        <span className="absolute bottom-0 left-0 w-full h-0.5 bg-blue-600"></span>
                    )}
                </button>
            ))}
        </div>
    );
};

export default MinimalistTabs;