import React from 'react';
import { FileText, Upload, CheckCircle, GraduationCap } from 'lucide-react';

const TimelineItem = ({ item, index }) => (
    <div className={`flex w-full ${index % 2 === 0 ? 'justify-end' : 'justify-start'} `}>
        <div className={`w-[44%] ${index % 2 === 0 ? 'text-right pr-8' : 'text-left pl-8'}`}>
            <h3 className="text-md font-semibold mb-2 text-black text-start">{item.title}</h3>
            <p className="text-sm text-gray-600 text-start ">{item.cardDetailedText}</p>
        </div>
    </div>
);

const TimelineIcon = ({ icon, index }) => (
    <div className="absolute left-1/2 transform -translate-x-1/2 flex items-center justify-center w-8 h-8 rounded-full bg-blue-500 z-10">
        {icon}
    </div>
);

const CourseEnlistmentProcessSm = () => {
    const items = [
        {
            title: "Enter Course Details",
            cardDetailedText: "Fill out the form with your course title, description, and other relevant details.",
            icon: <FileText className="text-white" size={20} />
        },
        {
            title: "Course Submitted for Verification",
            cardDetailedText: "We verify that the playlist enlist request is made by the actual owner of the playlist.",
            icon: <Upload className="text-white" size={20} />
        },
        {
            title: "Approval or Rejection",
            cardDetailedText: "Our team carefully reviews your course. You'll be notified of the decision.",
            icon: <CheckCircle className="text-white" size={20} />
        },
        {
            title: "Addition to Courses Section",
            cardDetailedText: "If approved, your course is added to the main courses section for all users to access.",
            icon: <GraduationCap className="text-white" size={20} />
        }
    ];

    return (
        <div className="w-full max-w-6xl mx-auto py-4  p-1">
            <h2 className="text-2xl font-bold text-center mb-12 text-black">Course Enlistment Process</h2>
            <div className="relative">
                <div className="absolute left-1/2 top-0 bottom-0 w-0.5 bg-blue-200"></div>
                {items.map((item, index) => (
                    <div key={index} className="relative mb-8">
                        <TimelineIcon icon={item.icon} index={index} />
                        <TimelineItem item={item} index={index} />
                    </div>
                ))}
            </div>
            
        </div>
    );
};

export default CourseEnlistmentProcessSm;
