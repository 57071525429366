import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const EmailVerification = () => {
    const [verificationStatus, setVerificationStatus] = useState('verifying');
    const { token } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const verifyEmail = async () => {
            try {
                const response = await axios.post('/auth/verify-email', { token });
                if (response.data.success) {
                    setVerificationStatus('verified');
                    setTimeout(() => navigate('/signin'), 3000); 
                } else {
                    setVerificationStatus('failed');
                }
            } catch (error) {
                console.error('Verification failed:', error);
                setVerificationStatus('failed');
            }
        };

        verifyEmail();
    }, [token, navigate]);

    const renderContent = () => {
        switch (verificationStatus) {
            case 'verifying':
                return (
                    <>
                        <h1 className="text-4xl font-bold text-blue-500 mb-6">Verifying...</h1>
                        <p className="text-xl text-gray-700">Please wait while we verify your email.</p>
                    </>
                );
            case 'verified':
                return (
                    <>
                        <h1 className="text-4xl font-bold text-green-500 mb-6">Verified!</h1>
                        <p className="text-xl text-gray-700 mb-4">Your email has been successfully verified.</p>
                        <p className="text-lg text-blue-500">You can now login to your account.</p>
                        <p className="text-sm text-gray-500 mt-4">Redirecting to login page...</p>
                    </>
                );
            case 'failed':
                return (
                    <>
                        <h1 className="text-4xl font-bold text-red-500 mb-6">Verification Failed</h1>
                        <p className="text-xl text-gray-700 mb-4">We couldn't verify your email.</p>
                        <p className="text-lg text-gray-600">Please try again or contact support.</p>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-[30rem] bg-gray-100">
            <div className="text-center p-8 bg-white rounded-lg shadow-lg max-w-md w-full">
                {renderContent()}
            </div>
        </div>
    );
};

export default EmailVerification;