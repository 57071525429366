import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const DropDownLg = ({ loading, categories, categoryFetchError }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeCategory, setActiveCategory] = useState(0);
    const [subCategoriesOpen, setSubCategoriesOpen] = useState(false);
    const [activeSubCategory, setActiveSubCategory] = useState(null);
    const dropdownRef = useRef(null);
    const timeoutRef = useRef(null);
    const navigate = useNavigate();

    const skeletonWidths = useMemo(() => {
        const generateRandomWidths = (count) => {
            return Array.from({ length: count }, () => Math.floor(Math.random() * (40 - 30 + 1)) + 20);
        };
        return generateRandomWidths(10);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                closeDropdown();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
        };
    }, []);

    const closeDropdown = () => {
        setIsOpen(false);
        setSubCategoriesOpen(false);
        setActiveSubCategory(null);
    };

    const handleCategoryMouseEnter = (index) => {
        setActiveCategory(index);
        setSubCategoriesOpen(true);
        setActiveSubCategory(null);
    };

    const handleSubCategoryMouseEnter = (index) => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        setActiveSubCategory(index);
    };

    const handleSubCategoryMouseLeave = () => {
        timeoutRef.current = setTimeout(() => {
            setActiveSubCategory(null);
        }, 100); // Slight delay before closing
    };

    const handleCategoryClick = (categoryName) => {
        closeDropdown();
        navigate(`/category/${categoryName}`);
    };

    const handleSubCategoryClick = (subCategoryName) => {
        closeDropdown();
        navigate(`/subcategory/${subCategoryName}`);
    };

    const handleSubSubCategoryClick = (subSubCategoryName) => {
        closeDropdown();
        navigate(`/subsubcategory/${subSubCategoryName}`);
    };

    return (
        <div
            className="relative hidden lg:block"
            ref={dropdownRef}
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={closeDropdown}
        >
            <div className="p-4 cursor-pointer text-gray-700 hover:text-black">
                Categories
            </div>
            {isOpen && (
                <div className="absolute left-0 mt-0 rounded-md flex min-h-[26rem] bg-white border border-gray-300 shadow-lg z-20 text-black">
                    <div className="w-64 border-r border-gray-300 flex flex-col justify-start text-left">
                        {!loading && categoryFetchError ? (
                            <div className="px-4 py-2 text-red-500">
                                Error: {categoryFetchError}
                            </div>
                        ) : loading ? (
                            <div className="px-4 py-2 w-full h-full flex gap-2 flex-col">
                                {skeletonWidths.map((width, index) => (
                                    <div
                                        key={index}
                                        className={`h-6 bg-gray-200 rounded w-${width} animate-pulse mb-2`}
                                    ></div>
                                ))}
                            </div>
                        ) : (
                            categories && categories.map((category, index) => (
                                <div
                                    key={index}
                                    className="relative"
                                    onMouseEnter={() => handleCategoryMouseEnter(index)}
                                    onClick={() => handleCategoryClick(category.categoryName)}
                                >
                                    <div
                                        className={`px-4 pl-6 py-2 cursor-pointer hover:bg-gray-100 ${activeCategory === index ? 'bg-gray-200 font-bold' : ''}`}
                                    >
                                        {category.categoryName}
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                    {subCategoriesOpen && (
                        <div className="w-64 border-r border-gray-300 text-left">
                            {categories && categories[activeCategory] && categories[activeCategory].subcategories.map((subCategory, subIndex) => (
                                <div
                                    key={subIndex}
                                    className="relative"
                                    onMouseEnter={() => handleSubCategoryMouseEnter(subIndex)}
                                    onMouseLeave={handleSubCategoryMouseLeave}
                                    onClick={() => handleSubCategoryClick(subCategory.name)}
                                >
                                    <div
                                        className={`px-4 pl-6 py-2 cursor-pointer hover:bg-gray-100 ${activeSubCategory === subIndex ? 'bg-gray-200 font-bold' : ''}`}
                                    >
                                        {subCategory.name}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {subCategoriesOpen && activeSubCategory !== null && (
                        <div
                            className="w-64 text-left"
                            onMouseEnter={() => {
                                if (timeoutRef.current) clearTimeout(timeoutRef.current);
                            }}
                        >
                            {categories && categories[activeCategory] && categories[activeCategory].subcategories[activeSubCategory] && categories[activeCategory].subcategories[activeSubCategory].subSubcategories.map((subSubCategory, subSubIndex) => (
                                <div
                                    key={subSubIndex}
                                    className="px-4 py-2 pl-6 hover:bg-gray-100 cursor-pointer"
                                    onClick={() => handleSubSubCategoryClick(subSubCategory.name)}
                                >
                                    {subSubCategory.name}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default DropDownLg;