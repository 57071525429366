import { AnimatePresence, motion } from "framer-motion";
import { FiCheck, FiLoader, FiX } from "react-icons/fi";

const LoadAndErrorButton = ({
    text = "Click Me",
    variant = "neutral",
    onClick,
    isEnabled = true,
    className = "",
    style = {}
}) => {
    const classNames =
        variant === "neutral"
            ? "bg-blue-500 hover:bg-blue-600"
            : variant === "error"
                ? "bg-red-500"
                : variant === "success"
                    ? "bg-green-500"
                    : variant === "delete"
                        ? "bg-red-500 hover:bg-red-600"
                        : "bg-indigo-300 pointer-events-none";

    return (
        <motion.button
            disabled={(variant !== "neutral" && variant !== "delete") || !isEnabled}
            onClick={onClick}
            className={`relative rounded-md px-4 py-2 font-medium text-white transition-all ${classNames} ${(!isEnabled) ? 'opacity-50 cursor-not-allowed' : ''} ${className}`}
            style={style}
        >
            <motion.span
                animate={{
                    y: variant === "neutral" || variant === "delete" ? 0 : 6,
                    opacity: variant === "neutral" || variant === "delete" ? 1 : 0,
                }}
                className="inline-block"
            >
                {text}
            </motion.span>
            <IconOverlay Icon={FiLoader} visible={variant === "loading"} spin />
            <IconOverlay Icon={FiX} visible={variant === "error"} />
            <IconOverlay Icon={FiCheck} visible={variant === "success"} />
        </motion.button>
    );
};

const IconOverlay = ({ Icon, visible, spin = false }) => {
    return (
        <AnimatePresence>
            {visible && (
                <motion.div
                    initial={{
                        y: -12,
                        opacity: 0,
                    }}
                    animate={{
                        y: 0,
                        opacity: 1,
                    }}
                    exit={{
                        y: 12,
                        opacity: 0,
                    }}
                    className="absolute inset-0 grid place-content-center"
                >
                    <Icon className={`text-xl duration-300 ${spin ? "animate-spin" : ""}`} />
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default LoadAndErrorButton;
