import axios from "axios";
import React, { Suspense, useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import CourseCard from "../../../components/CourseCard";
import CourseCardSkeleton from "../../../components/skeletons/CourseCardSkeleton";


const SubSubCategory = () => {
    const { subsubcategoryName } = useParams();
    const location = useLocation();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [subSubCategoryCourses, setsubSubCategoryCourses] = useState([]);

    useEffect(() => {
        const fetchCourseBySubSubCategory = async () => {
            try {
                setLoading(true);
                setsubSubCategoryCourses([]);
                if (!subsubcategoryName) return;

                const res = await axios.get('/course/getCourseBySubSubCategory', {
                    params: { subsubcategoryName }
                });

                if (res.data.success) {
                    setsubSubCategoryCourses(res.data.courses);
                } else {
                    setError(res.data.message || 'An error occurred while fetching courses.');
                }
            } catch (err) {
                if (err.response && err.response.status === 404) {
                    setError('No courses found for the specified subcategory.');
                } else {
                    setError('An error occurred while fetching courses.');
                }
                console.error(`Failed to fetch subcategory courses: ${err}`);
            } finally {
                setLoading(false);
            }
        };

        fetchCourseBySubSubCategory();
    }, [location.pathname, subsubcategoryName]);

    if (loading) {
        return (
            <>
                <div className="flex justify-center items-center">
                    <div className="animate-pulse h-8 mt-2 bg-gray-300 rounded-md w-[25%] ">
                    </div>
                </div>

                <div className="grid grid-cols-1 w-[93%] md:w-full lg:w-full gap-6 md:grid-cols-3 lg:grid-cols-4 my-4 min-h-svh p-5">
                    {[...Array(8)].map((_, index) => (
                        <CourseCardSkeleton key={index} />
                    ))}
                </div>
            </>
        );
    }

    if (error) {
        return (
            <>
                <h1 className="lg:text-3xl text-2xl text-black font-bold text-center pt-4">{subsubcategoryName}</h1>
                <div className="text-red-500 flex-col my-4 min-h-[30rem] flex text-xl justify-center items-center">
                    <p>{error}</p>
                </div>
            </>
        );
    }

    return (
        <div>
            <h1 className="lg:text-3xl text-2xl tracking-wider font-bold pt-3 mb-3 text-black">{subsubcategoryName}</h1>
            <div className="grid grid-cols-1 w-[93%] md:w-full lg:w-full gap-6 md:grid-cols-3 lg:grid-cols-4 my-4 min-h-svh p-10">
                {subSubCategoryCourses.length > 0 &&
                    subSubCategoryCourses.map((item) => (
                        <Suspense key={item._id} fallback={<CourseCardSkeleton />}>
                            <CourseCard item={item} />
                        </Suspense>
                    ))}
            </div>
        </div>
    );
};

export default SubSubCategory;